var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  function _setSmooshColor(skuId, $product) {
    var $smoosh = $('.js-product-smoosh', $product);
    var $smooshImg = $('.js-product-smoosh-img', $product);
    var sku;

    if (skuId) {
      sku = prodcat.data.getSku(skuId);
      if (sku && sku.HEX_VALUE_STRING) {
        $smoosh.css('background-color', sku.HEX_VALUE_STRING);
        $smooshImg.attr('src', sku.SMOOSH_DESIGN);
      }
    }
  }

  $(document).on('product.skuSlide.display', '.js-product', function (e, skuId) {
    var $productImageContainer = $('.js-product-full__image', $(this));
    var $productCarousel = $('.js-spp-carousel', $(this));
    var isQuickshop = $(this).hasClass('js-quickshop');
    var skuImage = '';
    var skuImageSource = '';
    var $slide, $skuSlide, currentSlide, sku;

    if (!skuId || $productImageContainer.length < 1 || isQuickshop || !$productCarousel.length) {
      return;
    }
    $slide = $('.js-product-image', $productCarousel);
    currentSlide = $productCarousel.data().currentSlide - 0 || 0;
    $skuSlide = $slide.eq(currentSlide + 1);
    sku = prodcat.data.getSku(skuId);
    skuImageSource = sku.LARGE_IMAGE[0] + '';
    skuImage = skuImageSource.replace(/_\d+\./gi, '_' + currentSlide + '.');
    $skuSlide.attr('src', skuImage);
  });

  $(document).on('product.skuSelect product.skuSlide.display', '.js-product', function (e, skuId) {
    var $shadeDescContainer = $(
      '.js-product-full__shade-description, .js-product-quickshop__shade-description',
      $(this)
    );
    var skuDesc = '';
    var sku;

    if (!skuId || $shadeDescContainer.length < 1) {
      return;
    }
    sku = prodcat.data.getSku(skuId);
    skuDesc = sku.SHADE_DESCRIPTION;
    if (skuDesc) {
      $shadeDescContainer.html(skuDesc);
    }
  });

  $(document).on('click', '.js-product-shade', function (event) {
    event.preventDefault();
    var skuId = $(this).data('sku');

    if (skuId) {
      var sku = prodcat.data.getSku(skuId);
      var skuBaseId;

      if (sku) {
        skuBaseId = sku.SKU_BASE_ID;
        var $product = $(this).closest('.js-product');

        $product.trigger('product.skuSelect', [skuBaseId]);

        // Toggle the active state of the selected shades
        $product.find('.js-product-shade').removeClass('active');
        $(this).addClass('active');
      }
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('.js-product-shade', this).removeClass('active');
    $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', this).addClass('active');
    _setSmooshColor(skuBaseId, $(this));
    $('select.js-sku-menu', this).val(skuBaseId);
    $(this).trigger('product.skuSlide.display');
  });

  $(document).on('mouseenter', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $(this).data('sku');

    _setSmooshColor(skuId, $product);
    $product.trigger('product.skuSlide.display', [skuId]);
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $product.data('sku-base-id');

    _setSmooshColor(skuId, $product);
    $product.trigger('product.skuSlide.display', [skuId]);
  });
})(jQuery);
